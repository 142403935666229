<template>
    <div class="bkColor">
        <headTitle :title="title"></headTitle>
        <div class="list">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                    finished-text="没有更多了" @load="getSupplierList">
                    <div class="listCell" v-for="item in list" :key="item.id" @click="goDetails(item.id, item.configName)">
                        <div class="cellName">{{ item.configName }}</div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>

    </div>
</template>
    
<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import {
    Search,
    Toast,
    List,
    PullRefresh,
    Cell,
} from "vant";

import { configList } from "../api/config";

import { getToken } from "../units/auth";

Vue.use(Search);
Vue.use(Toast);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);
export default {
    name: "configList",
    components: {
        headTitle,
    },
    data() {
        return {
            title: "配置列表",
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            error: false,
            queryFrom: {
                vague: "",
                pageSize: 10,
                pageNum: 1,
            },

            powerList: [], //value 1总经理 2财务 3行政
            isModify: false,

            equipment: false
        };
    },
    mounted() {

        this.getPowerList();
        this.isMobile()
    },
    methods: {
        //获取权限渲染页面
        getPowerList() {
            this.powerList = JSON.parse(getToken("powerList") || "[]");
        },
        getSupplierList() {
            this.loading = true;
            configList(this.queryFrom).then((res) => {
                this.list =
                    this.queryFrom.pageNum == 1
                        ? res.data.rows
                        : this.list.concat(res.data.rows);
                this.refreshing = false;
                this.loading = false;
                this.queryFrom.pageNum = this.queryFrom.pageNum + 1;
                if (
                    res.data.total <=
                    (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize
                ) {
                    this.finished = true;
                    this.list.forEach(it => {
                        console.log(it.id)
                    })
                }
            });
        },

        //下拉刷新
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.queryFrom.pageNum = 1;
            this.list = [];
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.getSupplierList();
        },

        goDetails(id, title) {
            if (this.isDrag) {
                return;
            }
            this.$router.push({
                path: "/configDetailList",
                query: { id, title },
            });
        },

        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        }
    },
};
</script>
    
<style lang="less" scoped>
.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #f6f6f6;
    position: relative;
}

/deep/ .van-ellipsis {
    font-size: 13px;
}

.list {
    margin-top: 2vh;
}

.listCell {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 5vw 1vh;
    background: #fff;
    text-align: left;
}

.cellName {
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    border-top: 2px solid #f6f6f6;
    padding-top: 1vh;
    padding-bottom: 1vh;

    .status {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
        text-align: right;
    }
}

/deep/ .van-list :first-of-type.listCell .cellName {
    border-top: unset;
}

.cellText {
    width: 70%;
    color: #797979;
    margin-bottom: 0.5vh;
    font-size: 0.7rem;
    word-break: break-all;
    line-height: 1;
}
</style>